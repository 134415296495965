import React, {Component} from 'react';

import Aux from '../../../hoc/Aux/Aux';
import Button from '../../UI/Button/Button';

class OrderSummary extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('Burger summary is updated')
  }

  render () {
    const ingredientSummary = Object.keys(this.props.ingredients)
      .map(ingredient =>
        <li key={ingredient}>
          <span style={{textTransform: 'capitalize'}}>{ingredient}</span>: {this.props.ingredients[ingredient]}
        </li>
      );
    return (
      <Aux>
        <h3> Your order</h3>
        <p>A delicious burger with the following ingredients</p>
        <ul>
          {ingredientSummary}
        </ul>
        <p><strong>Total price</strong>: {this.props.price.toFixed(2)}</p>
        <p>Continue to checkout</p>
        <Button btnType='Danger' clicked={this.props.cancelHandler}>CANCEL</Button>
        <Button btnType='Success' clicked={this.props.continueHandler}>CHECKOUT</Button>
      </Aux>
    )
  }
};

export default OrderSummary;