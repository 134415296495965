import React,{useEffect} from 'react';
import classes from './Modal.scss';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

const Modal = React.memo((props) => {
  return(
    <Aux>
      <Backdrop show={props.show} clicked={props.cancel}/>
      <div
        className={classes.Modal}
        style={{
          transform: props.show ? 'translateY(0)': 'translateY(-100vh)',
          opacity: props.show ? '1' : '0'
        }}
      >
        {props.children}
      </div>
    </Aux>
  )
}, (prevProps, nextProps) => (prevProps.show === nextProps.show) && (nextProps.children === prevProps.children));

export default Modal;