import React from 'react';
import PropTypes from 'prop-types';
import classes from './DrawerToggleButton.scss';

const drawerToggleButton = props => {
  return (
    <div className={classes.DrawerToggle} onClick={props.clicked}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

drawerToggleButton.propTypes = {

};

export default drawerToggleButton;