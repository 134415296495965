import React from 'react';
import classes from './Button.scss';
import PropTypes from 'prop-types';

const button = (props) => (
  <button
    className={[classes.Button, classes[props.btnType]].join(' ')}
    onClick={props.clicked}>
    {props.children}
  </button>
);

button.propTypes = {
  btnType: PropTypes.oneOf(['Success', 'Danger']),
  clicked: PropTypes.func.isRequired
};


export default button;