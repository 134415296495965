import React, {Component} from 'react';

import Aux from '../../hoc/Aux/Aux';
import Burger from '../../components/Burger/Burger';
import BuildControls from '../../components/Burger/BuildControls/BuildControls';
import Modal from '../../components/UI/Modal/Modal';
import OrderSummanry from '../../components/Burger/OrderSummary/OrderSummary';
import Spinner from '../../components/UI/Spinner/Spinner';
import axios from '../../axios-order';

const INGREDIENTS_PRICE = {
  salad: 0.5,
  cheese: 0.4,
  meat: 1.3,
  bacon: 0.7,
};

class BurgerBuilder extends Component {
  state = {
    ingredients: {
      salad: 0,
      bacon: 0,
      cheese: 0,
      meat: 0,
    },
    totalPrice: 0,
    purchasable: false,
    purchasing: false,
    loading: false
  };

  updatePurchaseState () {
    this.setState((prevState, props) => {
      //Need to call setState like this because this function is call after a setState => the state in this function will not be correct
      // => call setState in function way will ensure that we have the latest version of state
      const ingredients = {
        ...prevState.ingredients
      };
      const sum = Object.keys(ingredients)
        .map(ingredient => ingredients[ingredient])
        .reduce((sum, el) => sum = sum + el, 0);
      return {purchasable: (sum > 0)};
    });
  }

  addIngredientHandler = (type) => {
    const oldCount = this.state.ingredients[type];
    const newCount = oldCount + 1;
    const newIngredients = {
      ...this.state.ingredients
    };
    newIngredients[type] = newCount;
    const newPrice = this.state.totalPrice + INGREDIENTS_PRICE[type];

    this.setState({ ingredients: newIngredients, totalPrice: newPrice});
    this.updatePurchaseState();
  };

  removeIngredientHandler = (type) => {
    const oldCount = this.state.ingredients[type];
    if (oldCount > 0) {
      const newCount = oldCount - 1;
      const newIngredients = {
        ...this.state.ingredients
      };
      newIngredients[type] = newCount;
      const newPrice = this.state.totalPrice - INGREDIENTS_PRICE[type];
      this.setState({ ingredients: newIngredients, totalPrice: newPrice});
      this.updatePurchaseState();
    }
  };

  purchaseHandler = () => {
    this.setState({purchasing: true});
  };

  purchaseCancelHandler = () => {
    this.setState({purchasing: false});
  };

  purchaseContinueHandler = () => {
    // alert('You continue!');
    this.setState({loading: true});
    const order = {
      ingredients: this.state.ingredients,
      price: this.state.totalPrice,
      customer: {
        name: 'tht',
        address: {
          street: 'AAAA',
          zipCode: '75014',
          country: 'France'
        },
        email: 'thieuhuutrung@gmail.com'
      },
      deliveryMethod: 'fastest'
    };
    axios.post('/orders.json', order)
      .then(response => {
        console.log(response);
        this.setState({loading: false, purchasing: false});
      })
      .catch(error => {
        console.log(error)
        this.setState({loading: false, purchasing: false});
      })
  };

  render () {
    const disableInfo = {...this.state.ingredients};
    Object.keys(disableInfo).forEach(ingredient => disableInfo[ingredient] = this.state.ingredients[ingredient] === 0);
    let orderSummary = <OrderSummanry
      price={this.state.totalPrice}
      ingredients={this.state.ingredients}
      continueHandler={this.purchaseContinueHandler}
      cancelHandler={this.purchaseCancelHandler}
    />;
    if (this.state.loading) {
      orderSummary = <Spinner/>;
    }
    return(
      <Aux>
        <Modal show={this.state.purchasing} cancel={this.purchaseCancelHandler}>
          {orderSummary}
        </Modal>
        <Burger ingredients={this.state.ingredients}/>
        <BuildControls
          purchasable={this.state.purchasable}
          price={this.state.totalPrice}
          disableInfo={disableInfo}
          addHandler={this.addIngredientHandler}
          removeHandler={this.removeIngredientHandler}
          ordered={this.purchaseHandler}
        />
      </Aux>
    );
  }
}

export default BurgerBuilder;