import React, {Component} from 'react';
import Aux from '../Aux/Aux';
import classes from './Layout.scss';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
  state = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState({showSideDrawer: false});
  };

  sideDrawerOpenedHandler = () => {
    this.setState({showSideDrawer: true});
  };

  render() {
    return (
      <Aux>
        <SideDrawer
          open={this.state.showSideDrawer}
          closeHandler={this.sideDrawerClosedHandler}/>
        <Toolbar openSideDrawer={this.sideDrawerOpenedHandler}/>
        <main className={classes.Content}>
          {this.props.children}
        </main>
      </Aux>
    )
  }
}

export default Layout;