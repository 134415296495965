import React from 'react';
import PropTypes from 'prop-types';
import classes from './Spinner.scss';

const spinner = props => {
  return (
    <div className={classes.Loader}></div>
  );
};

spinner.propTypes = {
  
};

export default spinner;