import React from 'react';
import classes from './BuildControl.scss';

const buildControl = (props) => (
    <div className={classes.BuildControl}>
      <div className={classes.Label}>{props.label}</div>
      <button disabled={props.disabled} className={classes.Less} onClick={props.removeHandler}>Less</button>
      <button className={classes.More} onClick={props.addHandler}>More</button>
    </div>
)

export default buildControl;