import React from 'react';
import PropTypes from 'prop-types';
import burgerLogo from '../../assets/burger-logo.png';
import classes from './Logo.scss';

const logo = props => {
  return (
    <div className={classes.Logo}>
      <img src={burgerLogo} alt='My Burger'/>
    </div>
  );
};

logo.propTypes = {
  
};

export default logo;