import React from 'react';
import PropTypes from 'prop-types';
import classes from './Toolbar.scss';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToogleBUtton from '../SideDrawer/DrawerToggleButton/DrawerToggleButton';

const toolbar = props => {
  return (
    <header className={classes.Toolbar}>
      <DrawerToogleBUtton clicked={props.openSideDrawer}/>
      <div style={{height: '80%'}}>
        <Logo />
      </div>
      <nav  className={classes.DesktopOnly}>
        <NavigationItems/>
      </nav>
    </header>
  );
};

toolbar.propTypes = {
  
};

export default toolbar;