import React from 'react';
import PropTypes from 'prop-types';

import BurgerIngredient from './BurgerIngredient/BurgerIngredient';
import classes from './Burger.scss';

const burger = (props) => {
  let transformIngredient = Object.keys(props.ingredients)
    .map(igKey => [...Array(props.ingredients[igKey])]
      .map((_, i) => <BurgerIngredient type={igKey} key={igKey+i}/>))
    .reduce((arr, el) => arr.concat(el), []);
  if (transformIngredient.length === 0) {
    transformIngredient = <p>Please start adding ingredients!!!</p>
  }
  return (
    <div className={classes.Burger}>
      <BurgerIngredient type="bread-top"/>
      {transformIngredient}
      <BurgerIngredient type="bread-bottom"/>
    </div>
  );
};

burger.propTypes = {
  ingredients: PropTypes.object.isRequired
}

export default burger;