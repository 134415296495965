import React from 'react';
import classes from './BuildControls.scss';
import BuildControl from './BuildControl/BuildControl';

const controls = [
  {label: 'Salad', type: 'salad'},
  {label: 'Bacon', type: 'bacon'},
  {label: 'Cheese', type: 'cheese'},
  {label: 'Meat', type: 'meat'},
];

const buildControls = (props) => (
  <div className={classes.BuildControls}>
    <div>Current price: <strong>{props.price.toFixed(2)}</strong></div>
    {controls.map(control =>
      <BuildControl
        key={control.type}
        label={control.label}
        disabled={props.disableInfo[control.type]}
        addHandler={() => props.addHandler(control.type)}
        removeHandler={() => props.removeHandler(control.type)}
      />)}
      <button
        disabled={!props.purchasable}
        className={classes.OrderButton}
        onClick={props.ordered}
      >ORDER NOW</button>
  </div>
);

export default buildControls;